import React, { useState,useContext } from 'react'
import axios from 'axios'
import { loginStatus } from '../globalstate'
import { Link } from 'react-router-dom';


const Usertags = ()=>{

    const logState = useContext(loginStatus)
    const { loginState } = logState

    const [usertagname, setUsertagname] = useState("")
    const [problemcode, setProblemcode]=useState("")

    const userTagFun = (e)=> setUsertagname(e.target.value)
    const probleCodeFun =(e)=> setProblemcode(e.target.value)
    const [userTagStatus, setUserTagStatus] = useState("")
    
    const actionTag = async(param)=>{
        const token = localStorage.getItem('token')
        const url = "http://codechefroshan.freecluster.eu/api/"+param
        await axios.post(url, {
            usertag:usertagname,
            questioncode:problemcode
        },
        {
                headers:{Authorization: "Bearer "+token}
        })
        .then((res)=>{
            setUserTagStatus(res.data.status)
            setTimeout(() => {
                setUserTagStatus("")
            }, 1000);
            
            setUsertagname("")
            setProblemcode("")
        })
        .catch((err)=>{
            setUserTagStatus("Unauthorized")
            setUsertagname("")
            setProblemcode("")
        })
    }

    const add =()=>{
        if(usertagname.length>0 && problemcode.length>0)actionTag("addtag")
        else{
             setUserTagStatus("names cannot be empty")
             setTimeout(() => {
                setUserTagStatus("")
            }, 1000);
        }
    }
    const removeTag =()=>{
        if(usertagname.length>0 && problemcode.length>0)actionTag("removetag")
        else{
             setUserTagStatus("names cannot be empty")
             setTimeout(() => {
                setUserTagStatus("")
            }, 1000);
        }
    }
return(
    <>
    {loginState?(
        <div className="card w-25 container center_div mt-5">
                <h5 className="card-header info-color white-text text-center py-4">Add/Remove tag</h5>
                <div className="card-body px-lg-5 pt-0">
                <input className="form-control mt-2" type="text" value={usertagname} onChange={userTagFun} placeholder="Tag Name" />
                <input className="form-control mt-2" type="text" value={problemcode} onChange={probleCodeFun} placeholder="Problem Code" />
                    <button className="btn container center_div  btn-info btn-block my-2 " fontSize="large" onClick={add}>Add tag</button>
                    <button className="btn container center_div  btn-info btn-block my-2 " fontSize="large" onClick={removeTag}>remove tag</button>
            <span style={{color: "#029adb",fontSize:"2.5rem"}}>{userTagStatus}</span>
            </div>
        </div>):
        (
            <div className="container w-25 center_div mt-5 d-flex flex-column align-items-center">
                <h3>You need to login to add tags... </h3>
                <h5 className="mt-5">Go to Login...</h5>
                <Link className="mt-5" to="/login">Login</Link>
            </div>
        )
        }
    </>
)

}

export default Usertags
