import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { StateProvider } from './components/globalstate';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"

ReactDOM.render(
  <StateProvider>
  <BrowserRouter>
    <App />
    </BrowserRouter>
    </StateProvider>,
  document.getElementById('root')
);


