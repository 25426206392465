import React,{useContext} from 'react'
import {NavLink} from 'react-router-dom'
import { loginStatus } from './globalstate';

const Nav =()=>{
    const { loginState } = useContext(loginStatus)
    
    return(
    <>
            <nav className="navbar navbar-default bg-light" >
                <div class="navbar-header m-2">
                    <h3>Codechef</h3>
                </div>
                <NavLink  className="navItems"  exact activeClassName="navActive" to="/">Home</NavLink>
                <NavLink  className="navItems"  exact activeClassName="navActive" to="/usertag">User tag</NavLink>
                {
                    loginState?
                    <NavLink className="navItems"to="/logout">Logout</NavLink>:
                    <NavLink   className="navItems" exact  activeClassName="navActive" to="/login">Login</NavLink>
                }
            
           </nav>
    </>
    )
}

export default Nav