import React from 'react'

const Filteredtag = (props)=>{
    return(
        
        <>
            <div className="column m-2">
                <strong>{props.tagName}<span className="remove" style={{cursor:"pointer"}} onClick={(e)=>{
                    props.handleFun(e.target.previousSibling.wholeText)}}>-</span></strong>
                
            </div>
        </>
    )
}

export default Filteredtag