import React from 'react'

const Tablesearch =({filter, setFilter})=>{
    return(
        <>
            <div className=" mb-3 ">
                <input className="p-2" value={filter || ''} onChange={e=>setFilter(e.target.value)} placeholder="Search table..."/>
            </div>
        </>
    )
}

export default Tablesearch