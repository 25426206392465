import React from 'react'
import Tablestruct from './Tablestruct'


const Showalltags = (props)=>{
  
  
       const  columns = [
            {
              Header: 'Tagname',
              accessor: 'tag_name',
              disableSortBy: true

              },
            {
                Header: 'Count',
                accessor:'tag_count',
              },
            {
                Header: 'type',
                accessor: 'tag_type',
               }
  
      ]
      const data = props.location.data

  
    return(
        <>
       
       <div>
                    <h1 >All Tags</h1>
                    <div>
                    <Tablestruct columns={columns} data={data} />
                </div>
            </div>
        </>
    )
}

export default Showalltags