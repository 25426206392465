import React, {useState, useContext} from 'react'
import axios from 'axios';
import { loginStatus } from '../globalstate';
import { Redirect } from 'react-router-dom';


const Login = ()=>{

    const logState = useContext(loginStatus)
    const { loginState,setLoginState } = logState;
    const [user, setUser] = useState({
        name: '',
        email:'',
        password:''
    })
    const [reg, setReg] = useState(0)

    const [err, setErr] = useState('');

    const onChangeInput = e=>{
        const {name, value}=e.target;
        setUser({...user, [name]:value})
        setErr('')
    }

    const registerSubmit = async e =>{
        e.preventDefault()
        await axios.post('http://codechefroshan.freecluster.eu/api/register',{
            username:user.name,
            email:user.email,
            password:user.password
        })
        .then(res=>{
            setUser({name:'',email:'',password:''})
            setReg(0)
        })
        .catch(()=>{
               setErr("Invalid Data")
               setTimeout(() => {
                setErr("")
            }, 1000);
            }
        )
    }

    const LoginSubmit = async e =>{
        e.preventDefault()
            await axios.post('http://codechefroshan.freecluster.eu/api/login',{
                email:user.email,
                password:user.password
            })
            .then(res=>{
                setUser({name:'',email:'',password:''})
                localStorage.setItem('token', res.data.token)
                setLoginState(false) 
            })
            .catch(()=>{
                   setErr("Invalid credentials")
                   setTimeout(() => {
                    setErr("")
                }, 1000);
                }
            )
           

    }

   const showReg=()=> {
       setReg(1)
       setUser({name:'',email:'',password:''})
       setErr("")
}


   const showlog=()=>{
        setReg(0)
        setUser({name:'',email:'',password:''})
       setErr("")
   }
    return(
        loginState?<Redirect to='/'/>:
        (<>
        {
        reg?(
           <>
                    
                    <form onSubmit={registerSubmit} className=" container center_div text-center border border-light p-5 w-25">
                    <p className="h4 mb-4">Register</p>
                        <input className="form-control mb-4" type="text" name="name" 
                            placeholder="User Name" required value={user.name}  onChange={onChangeInput}/>
                        <input className="form-control mb-4" type="email" name="email" 
                            placeholder="Email" required value={user.email}  onChange={onChangeInput}/>
                        <input className="form-control mb-4" type="password" name="password"
                            placeholder="Password" required value={user.password} autoComplete="true"  onChange={onChangeInput}/>
                        <button className="btn btn-info btn-block my-4" type="submit">Register</button>
                            <p style={{color:"#06a1cc"}}>You have an account?<br/>
                            <span style={{cursor:"pointer"}} id="sp" onClick={showlog}>Login Now</span></p>
                        <h3 className="statuslogin">{err}</h3>
                    </form>
           </> 
        )
        :
        (
                
                    <form onSubmit={LoginSubmit} className=" container center_div text-center border border-light p-5 w-25">
                    <p className="h4 mb-4">Login</p>
                        <input className="form-control mb-4" type="email" name="email" 
                            placeholder="Email" required value={user.email} onChange={onChangeInput}/>
                        <input className="form-control mb-4" type="password" name="password" 
                            placeholder="Password" required value={user.password} autoComplete="true"  onChange={onChangeInput}/>
                        <button className="btn btn-info btn-block my-4" type="submit">Login</button>
                        <p style={{color:"#06a1cc"}}>You don't have an account?<br/>
                        <span style={{cursor:"pointer"}} id="sp" onClick={showReg}>Register Now </span></p>
                        <h3 className="statuslogin">{err}</h3>
                    </form>
            
        )
        }
    </>    
    )
    )
}

export default Login
