import React, {createContext, useReducer} from 'react';

const init = false;
const loginStatus = createContext(init);
const { Provider } = loginStatus;

const StateProvider = ( { children } ) => {
  const [loginState, setLoginState] = useReducer((state, action) => {
      const newState = action?false:true
    
  return newState
}, init);

  return <Provider value={{ loginState, setLoginState }}>{children}</Provider>;
};

export { loginStatus, StateProvider }