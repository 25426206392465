import React,{useEffect, useState,useContext} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import Filteredtag from './Filteredtag'
import { loginStatus } from '../globalstate'

const Tagfilter = (props)=>{
    const [taginput, setTaginput] = useState("")
    const [tagsdata,setTagsdata] = useState([{
        tag_name:"",
        tag_type:"",
        tag_count:0
    }])
    const [showSelectedTags, setShowSelectedTags]=useState([])
    const logState = useContext(loginStatus)
    const { loginState } = logState

   const getPrivateTags=async()=>{
        const token = localStorage.getItem('token')
        const res = await axios.get("http://codechefroshan.freecluster.eu/api/my/tags",
         {headers:{Authorization: "Bearer "+token}})
        

        const tarr = (res.data).map(val => ( {
            tag_name:val['tag_name'],
            tag_type:val['tag_type'],
            tag_count:val['tag_count']
         }))
         setTagsdata(prev=>[...prev,...tarr])
   }

    const  getTags=async()=>{
       const res = await axios.get("http://codechefroshan.freecluster.eu/api/tags")
 
       setTagsdata((res.data).map(val => ( {
        tag_name:val['tag_name'],
         tag_type:val['tag_type'],
         tag_count:val['tag_count']
     })))
       
       if(loginState){
        getPrivateTags()
       }
    }
    useEffect(()=>{
        getTags()
    },[])
    
    const inputChange=(event)=>{
        setTaginput(event.target.value)
    }
    const prefixCheck =(primaryStr, prefstr)=>{
        let i=0;
        if(prefstr.length>primaryStr.length)
            return false;
        else{
            while(i<prefstr.length){
                if(primaryStr[i]===prefstr[i]){
                    i++
                    continue
                }
                else
                    return false
            }
            return true
        }
        
    }
    
    const tagsSuggestion=  ()=>{
        var count=0
        const tagarr = []
        var val
        for(val of tagsdata){
            if(count>=5)
                break
            if(prefixCheck(val['tag_name'].toLowerCase(),taginput.toLowerCase())){
                count++;
                tagarr.push(val)
            }
        }
        return tagarr
    } 
    const onSuggClicked =(e)=>{
        setTaginput("")
        const type = e.currentTarget.dataset.tagtype
        const name =e.target.textContent
        setShowSelectedTags(prev=>{
            props.handleFun([...prev,{
                tag_name:name,
                tag_type:type
            }])
            return [...prev,{
                tag_name:name,
                tag_type:type
            }]
        })
        
    }
    const delTag = (deleteval)=>{
        const temparr = showSelectedTags
            var searchTerm = deleteval.slice(0, -1),
                index = -1;
            for(var i = 0, len = temparr.length; i < len; i++) {
                if (temparr[i].tag_name === searchTerm) {
                    index = i;
                    break;
                }
            }
             temparr.splice(index, 1);
             props.handleFun(temparr)
        setShowSelectedTags(temparr)
    }
    return(
        <div className="search d-flex flex-column mt-2 align-items-center">
                <h3 className="text-center">Search by tags</h3>
                <Link className="text-center" to={{
                        pathname:'/alltags',
                        data: tagsdata
                    }
                    }>show all</Link>
                <div className="mt-2">
                    <input className="p-1" list="tagsdata" value={taginput} onChange={inputChange} placeholder="Search tagsdata..."/>
                    {
                        taginput.length>0?
                        (<ul className="list-group">
                            {
                                taginput?
                                tagsSuggestion().map((val,index)=>{
                                        return (
                                            <li className="list-group-item" id={`dropDownSugg${index}`} data-tagtype = {val['tag_type']} onClick={onSuggClicked} value={val['tag_name']}>{val['tag_name']}</li>
                                        )
                                    })
                                :
                                null

                            }
                        </ul>):null
                    }
                </div>
                    {
                         showSelectedTags.length>0 && taginput.length===0?(
                        <div className="d-flex flex-column">
                            {
                                showSelectedTags.map((val)=>{
                                    return <Filteredtag tagName={val['tag_name']} handleFun={delTag}/>
                                })
                            }
                        </div>
                        ):null
                    }
               
    </div>
    )

}

export default Tagfilter
