import React, {useState, useEffect,useContext } from 'react'
import axios from 'axios'
import { loginStatus } from '../globalstate'
import Tablestruct from '../Tablestruct'
import Tagfilter from './Tagfilter'

const Homepage =()=>{
    var url =""
    const logState = useContext(loginStatus)
    const { loginState } = logState
    const [displayques,setDisplayques] = useState([])
    const [tagsselected,setTagsselected] = useState([{
        tag_name:"",
        tag_type:""
    }])

  
    const tagsselectedHandler = (e)=> Array.isArray(e)?setTagsselected([...e]): setTagsselected([{tag_name:e,tag_type:""}])
    
    const notuser = async(url)=>{
        await axios.get(url)
        .then((res)=>{setDisplayques(res.data)}
        )
        .catch(err=>{
            console.log(err.message);
        })       
    }

    const isuser = async(url)=>{
        const token = localStorage.getItem('token')
        const js = [...tagsselected]
        await axios.post(url,js,{ headers:{Authorization: "Bearer "+token} })
        .then((res)=>{
            setDisplayques(res.data)
        })
        .catch((err)=>{
          
        })
    }

    const showQues = async () => {
        //url according to tag_type-
        if(tagsselected.length===0 || tagsselected[0]['tag_name']==="") {
            url = "http://codechefroshan.freecluster.eu/api/questions"
            notuser(url)
        }
        else{
            if(loginState){
                url = "http://codechefroshan.freecluster.eu/api/my/questions"
                isuser(url)
             }
             else{
                 url="http://codechefroshan.freecluster.eu/api/questions"
                 tagsselected.map((val)=>
                     url+=`/${val['tag_name']}`
                 )
                 notuser(url)
             }
        } 
       

    }

    useEffect(()=>{
        showQues()
    },[tagsselected])
 
    const columns = [
        {
           
            Header: 'Ques Code',
            accessor: 'question_code',
            disableSortBy: true      
        },
          {
              
            Header: 'Question Name',
            accessor: 'question_name',
            disableSortBy: true
          },
          {
            Header: 'Author',
            accessor: 'author_name',
            disableSortBy: true
          },
          {
            
              Header: 'Solved',
              accessor:'solved',
            
            },
          {
              Header: 'Attempted',
              accessor: 'attempted',
            }

    ]
    
    return(
        <>
                <div  className="mt-3 ">
                    <div className="row  p-md-2">
                        <div className="col-md-9 ">
                            <Tablestruct  data={displayques} columns={columns}/>
                        </div>
                        <div className="col-md-3 ">
                            <Tagfilter handleFun={tagsselectedHandler} />
                        </div>
                    </div>
                </div>
        </>
    )

}

export default Homepage
