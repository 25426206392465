import React from 'react'
import { Route, Switch } from 'react-router-dom';
import Navigationbar from "./components/Navigationbar";
import Home from './components/HomePage/Homepage'
import Login from './components/Authenticated/Login'
import Showalltags from './components/Showalltags';
import Logout from './components/Authenticated/Logout';
import Usertags from './components/Authenticated/Usertags';


function App() {
 
  return (
    <>
      <div className="mainContainer">
        <Navigationbar />
        <Switch>
          <Route exact path="/" component ={Home}/>
          <Route exact path="/login" component ={Login}/>
          <Route exact path="/alltags" component ={Showalltags}/>
          <Route exact path="/logout" component ={Logout}/>
          <Route exact path="/usertag" component ={Usertags}/>
          <Route component={Home}/>
        </Switch>
      </div>
    </>
  );
}

export default App;
