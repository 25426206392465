import React from 'react'
import {useTable, useSortBy, usePagination, useGlobalFilter} from 'react-table'
import Globalfilter from './Tablesearch'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const Tablestruct = (props)=>{

   const generateSortingIndicator = column => {
  return (
    column.isSorted ? 
        (column.isSortedDesc ?
            <ArrowDropDownIcon fontSize="Large"/> : <ArrowDropUpIcon fontSize="Large"/>) :null
    )}
  
  
   
    const columns = props.columns
    const data = props.data
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter, 
        prepareRow,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize:6 }
    },useGlobalFilter ,useSortBy,usePagination)
    
    const {pageIndex,globalFilter}=state
    
    return (
        <div className="table-responsive tabletest" >
                <Globalfilter filter={globalFilter} setFilter={setGlobalFilter}/>
                <table {...getTableProps()} className="table table-hover table-striped table-fixed table-condensed ">
                     <thead>
                        {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                            {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                {column.render('Header')}
                                {generateSortingIndicator(column)}
                               
                                </th>
                            ))}
                        </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                                </tr>
                            )
                            })
                        }
                    </tbody>
                </table>
                <div className=" row justify-content-center  ">
                    <button onClick={()=>previousPage()}disabled={!canPreviousPage}>{'<'}</button>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex+1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <button onClick={()=>nextPage()} disabled={!canNextPage}>{'>'}</button>
                </div>
        </div>
    )
}

export default Tablestruct